.modal {
    --offset-top: 32px;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: calc(100vh - var(--offset-top));
    background-color: var(--background);
    overflow: hidden;
    transition: bottom 0.4s;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    flex-direction: column;
}

.hiding, .hidden {
    bottom: -100vh;
}

.hidden {
    height: 0;
    overflow: hidden;
}

.header {
    padding: 18px 18px;
}

.close {
    color: var(--color-inactive);
    font-size: 18px;
    text-decoration: none;
}

.body {
    flex-grow: 1;
    padding: 0 18px 24px 18px;
    max-height: calc(100% - 48px);
    box-sizing: border-box;
    overflow: scroll;
    color: var(--color-secondary-lighter);
}
