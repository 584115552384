.tag {
    padding: 6px 10px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
    background-color: var(--background-inactive);
    border: 0;
    color: var(--color-inactive);
}

.active {
    background-color: var(--background-primary);
    color: var(--color-primary);
}

.tag[disabled] {
    opacity: 0.5;
    pointer-events: none;
}