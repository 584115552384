.button {
    background-color: var(--background-inactive);
    color: var(--color-inactive);
    border: 0;
    border-radius: 5px;
    padding: 14px;
    font-size: 18px;
}

.primary {
    background-color: var(--background-primary);
    color: var(--color-primary);
}

.rounded {
    border-radius: 16px;
}

.grouped:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.grouped:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.nowrap {
    white-space: nowrap;
}

.button[disabled] {
    opacity: 0.5;
    pointer-events: none;
}