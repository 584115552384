.root {
  margin: 72px auto;
  width: 390px;
}

.header {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar {
  display: flex;
}

.toolbar > * + * {
  margin-left: 4px;
}

.body {
  padding: 28px 30px;
}

.footer {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.buttons > * {
    flex-grow: 1;
}

.buttons > * + * {
  margin-left: 2px;
}

.message {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hint {
  border: 1px solid currentColor;
  border-radius: 16px;
  margin-top: 16px;
  padding: 16px;
}

.hint > * + * {
  margin-top: 12px;
}

.result {
  color: var(--color-accent);
  font-size: 40px;
  text-transform: uppercase;
}