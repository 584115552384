.logo {
    display: flex;
    flex-direction: row;
}

.letter {
    width: 28px;
    height: 28px;
    background-color: var(--background-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary-light);
    font-weight: bold;
    font-size: 20px;
    border-radius: 3px;
}

.letter + .letter {
    margin-left: 2px;
}
