html {
  --background: #1c1c1e;
  --background-primary: #fade56;
  --background-secondary: #fff;
  --background-inactive: #5f5f5f;
  --background-overlay: #fff2;
  --color-primary: #000;
  --color-primary-light: #313336;
  --color-secondary: #000;
  --color-secondary-lighter: #888;
  --color-inactive: #fff;
  --color-accent: #fade56;
  --color-error: #ff0000;

  height: 100vh;
  overflow: hidden;
}

html, body {
  background-color: var(--background);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
