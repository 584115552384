.letter {
    --transition-speed: 0.1s;
    --transition-easing: linear;
    --size: 62px;

    box-sizing: border-box;
    min-width: var(--size);
    height: var(--size);
    border: 1px solid var(--background-primary);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    text-transform: uppercase;
    transition: background-color var(--transition-speed) var(--transition-easing),
        border-color  var(--transition-speed) var(--transition-easing),
        color  var(--transition-speed) var(--transition-easing);
}

.letter + .letter {
    margin-left: 5px;
}

.wrong {
    background-color: var(--background-inactive);
    border-color: var(--background-inactive);
    color: var(--color-inactive);
}

.correct {
    background-color: var(--background-secondary);
    border-color: var(--background-secondary);
    color: var(--color-secondary);
}

.exact {
    background-color: var(--background-primary);
    color: var(--color-primary);
}

.error {
    border-color: var(--color-error);
    border-width: 3px;
}