.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-overlay);
    transition: background-color 0.5s ease-in-out;
}

.hidden {
    background-color: transparent;
}