.button {
    border: 0;
    border-radius: 50%;
    padding: 14px;
    font-size: 18px;
    width: 52px;
    height: 52px;
    background-color: transparent;
}

.button[disabled] {
    opacity: 0.5;
    pointer-events: none;
}